import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  ratio?: string
}

const Frame = ({ ratio = '16:9', children }: Props): JSX.Element => {
  return (
    <S.Wrapper ratio={ratio} data-testid="eezy-layout-frame">
      {children}
    </S.Wrapper>
  )
}

export default Frame
