import { Routes } from 'constants/index'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthStore } from 'stores'
import { useInstance } from 'util/di'

const RedirectSession = () => {
  const { session } = useInstance<AuthStore>('AuthStore')

  if (!session) {
    return <Outlet />
  } else {
    return <Navigate to={Routes.Root} replace />
  }
}

export default RedirectSession
