import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  min: number
  space: number
  isWide: boolean
}>`
  align-content: start;
  display: grid;
  gap: ${({ space }) => themeSpacing(space)};
  grid-template-columns: ${({ min, isWide }) =>
    isWide ? `repeat(auto-fit, minmax(${String(min)}px, 1fr))` : '100%'};
`
