import { styled } from '@mui/material/styles'
import {
  Button,
  Modal,
  themeBorderRadius,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import { Split } from 'layouts/section'

import _FacebookIcon from 'ez-styles/assets/icons/social/facebook.svg'
import _LinkedInIcon from 'ez-styles/assets/icons/social/linkedin.svg'
import _WebsiteIcon from 'ez-styles/assets/icons/social/weblink.svg'
import _XIcon from 'ez-styles/assets/icons/social/x.svg'

export const StyledModal = styled(Modal)`
  & .MuiPaper-root {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(199, 199, 199);
      border-radius: 4px;
      &:hover {
        background-color: rgb(153, 153, 153);
      }
    }
  }
`

export const ModalBody = styled(Modal.Body)`
  gap: ${themeSpacing(4)};
`

export const SplitSection = styled(Split)`
  align-items: center;
  border: 1px solid ${themeColor('grey', '200')};
  border-radius: ${themeBorderRadius('medium')};
  padding: ${themeSpacing(2)};
`

export const AvatarSection = styled('div')<{
  $dragging?: boolean
}>`
  max-width: ${themeSpacing(12)};
  opacity: ${props => (props.$dragging ? 0.5 : 1)};
`

export const UploadWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const DropzoneContainer = styled('span')`
  outline: none;
  cursor: pointer;
`

export const UploadButton = styled(Button)`
  display: inline-block;
  margin: ${themeSpacing(2)} 0;
`

export const FormSection = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  gap: ${themeSpacing(4)};
`

export const SocialMediaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${themeSpacing(2)};
`

export const Image = styled('div')<{
  src?: string
}>`
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
  background-image: url('${props => props.src}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export const XIcon = styled(_XIcon)`
  fill: #000;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

export const FacebookIcon = styled(_FacebookIcon)`
  fill: #4b598f;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

export const LinkedInIcon = styled(_LinkedInIcon)`
  fill: #0077b5;
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

export const WebsiteIcon = styled(_WebsiteIcon)`
  width: ${themeSpacing(3)};
  height: ${themeSpacing(3)};
  margin-left: ${themeSpacing(1)};
`

export const CheckboxContainer = styled('div')`
  display: flex;
  align-items: center;

  p {
    margin-left: ${themeSpacing(1)};
  }
`

export const ErrorFooter = styled('div')`
  margin-top: ${themeSpacing(2)};
`
