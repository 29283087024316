import React, { useState, useRef, useEffect } from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  height?: string
  itemWidth?: string
  overflowing?: boolean
  space?: number
  thumbColor?: string
  trackColor?: string
  trackHeight?: number
}

const Reel = ({
  itemWidth = 'auto',
  overflowing = false,
  space = 1,
  height = 'auto',
  trackColor = 'transparent',
  thumbColor = 'transparent',
  trackHeight = 0,
  children,
}: Props): JSX.Element => {
  const reelRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(overflowing)
  const [elementWidth, setElementWidth] = useState(null)

  useEffect(() => {
    const element = reelRef?.current

    const updateElementWidth = () => {
      if (
        element &&
        reelRef &&
        reelRef.current &&
        reelRef.current.offsetWidth
      ) {
        setElementWidth(reelRef.current.offsetWidth)
        setIsOverflowing(element.scrollWidth > reelRef.current.offsetWidth)
      }
    }

    // Initial mount
    updateElementWidth()

    // Update on resize
    window.addEventListener('resize', updateElementWidth)

    // Cleanup
    return () => window.removeEventListener('resize', updateElementWidth)
  }, [elementWidth])

  return (
    <S.Wrapper
      itemWidth={itemWidth}
      space={space}
      height={height}
      trackColor={trackColor}
      thumbColor={thumbColor}
      trackHeight={trackHeight}
      overflowing={isOverflowing}
      ref={reelRef}
      data-testid="eezy-layout-reel"
    >
      {children}
    </S.Wrapper>
  )
}

export default Reel
