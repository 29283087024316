import { Typography } from '@mui/material'
import { Routes } from 'constants/index'
import { Button } from 'eezy-components'
import cookie from 'js-cookie'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Countdown from 'util/countdown'

import _HeroImage from '../referrals-hero2x.png'

import * as S from './styled'

type Props = {
  countdown: Countdown
}

/**
 * Interpolated translations:
 *
 * i18n-tasks-use t('referrals.toast.message')
 */
const ReferralsToast = ({ countdown }: Props): JSX.Element | null => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(!cookie.get('referrals-toast'))

  const onClick = () => {
    onClose()

    navigate(Routes.Referrals)
  }

  const onClose = () => {
    cookie.set('referrals-toast', 'true', { expires: 1 })
    setOpen(false)
  }

  if (!open) {
    return null
  }

  return (
    <S.Container data-testid="referrals-toast">
      <S.CloseIcon onClick={onClose} />
      <S.Image src={_HeroImage} />

      <S.Body>
        <Typography variant="h6">{t('referrals.toast.title')}</Typography>
        <S.Msg>
          <Trans
            i18nKey="referrals.toast.message"
            components={{ s: <s />, b: <strong /> }}
          />
        </S.Msg>
        <S.Msg>
          {t('referrals.toast.countdown', {
            date: Countdown.parse(countdown.duration),
          })}
        </S.Msg>

        <Button color="primary" onClick={onClick}>
          {t('referrals.toast.refer_a_friend')}
        </Button>
      </S.Body>
    </S.Container>
  )
}

export default observer(ReferralsToast)
