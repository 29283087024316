import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Session } from '../../../types'

import ContributorLinks from '../links/contributor'

import VecteezyIcon from 'ez-styles/assets/img/logos/rebrand/orange-horizontal-lockup.svg'

import * as S from './styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  session: Session
}

const Menu = ({ isOpen, onClose, session }: Props) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowOverlay(true)
      }, 350)
    }
  }, [isOpen])

  const onCloseSidebar = () => {
    setTimeout(() => setShowOverlay(false), 250)
    onClose()
  }

  const navigateTo = (route: string) => {
    if (route.includes('http') || route.includes('www')) {
      window.open(route, '_blank')
    } else {
      navigate(route)
    }

    onCloseSidebar()
  }

  return (
    <S.Container isOpen={isOpen} data-testid="mobile-menu">
      <S.Wrapper isOpen={isOpen} showOverlay={showOverlay}>
        {showOverlay ? (
          <>
            <S.LogoWrapper
              onClick={() => navigateTo('/')}
              data-testid="mobile-menu-logo"
            >
              <VecteezyIcon />
            </S.LogoWrapper>
            <ContributorLinks
              navigate={navigateTo}
              pathname={pathname}
              session={session}
            />
          </>
        ) : null}
      </S.Wrapper>
      <S.Overlay isOpen={showOverlay} onClick={onCloseSidebar} />
    </S.Container>
  )
}

export default Menu
