import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  centered?: string
  minHeight?: string
  noPadding?: boolean
  space?: number
}

const Cover = ({
  centered = 'h1',
  minHeight = '100vh',
  noPadding = false,
  space = 1.5,
  children,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      centered={centered}
      minHeight={minHeight}
      noPadding={noPadding}
      space={space}
      data-testid="eezy-layout-cover"
    >
      {children}
    </S.Wrapper>
  )
}

export default Cover
