import { Badge, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Button,
  themeBoxShadow,
  themeColor,
  themeSpacing,
} from 'eezy-components'
import { MobileProps } from 'helpers/hooks'

export const NoData = styled('div')`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 ${themeSpacing(2)};
`

export const StyledNotificationButton = styled(Button)`
  min-width: 0;
  padding: ${themeSpacing(1)} ${themeSpacing(2)};
  &.default {
    background: #fff;
  }
`

export const NotificationBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-size: ${themeSpacing(1.25)};
    font-weight: bold;
    border: 2px solid #fff;
  }
`

export const NotificationWindow = styled(Paper)`
  border: 1px solid ${themeColor('grey', '100')};
  box-shadow: ${themeBoxShadow('popper')};
  margin-top: ${themeSpacing(1)};
  width: 310px;
  position: relative;
`

export const NotificationList = styled('div')<MobileProps>`
  overflow-y: auto;
  max-height: ${(props: MobileProps) =>
    props.$isMobile ? '100%' : themeSpacing(84)}
  &:after {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    content: '';
    position: fixed;
    display: block;
    z-index: 1;
    height: ${themeSpacing(3)};
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const Notification = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeSpacing(2)};
  padding: ${themeSpacing(3)};
  position: relative;

  &:not(:first-child) {
    border-top: 1px solid ${themeColor('grey', '100')};
  }
`

export const Image = styled('img')`
  width: 100%;
  height: auto;
`

export const FillParent = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const ContainerLink = styled('a')`
  opacity: 0;
`

export const NotificationButton = styled(Button)`
  overflow: hidden;
`
