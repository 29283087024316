import React, { useState, useRef, useEffect } from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  min?: number
  space?: number
  className?: string
}

const Grid = ({
  min = 350,
  space = 1,
  children,
  className,
}: Props): JSX.Element => {
  const gridRef = useRef<HTMLDivElement>(null)
  const [elementWidth, setElementWidth] = useState(null)

  const handleElementResized = () => {
    if (
      gridRef &&
      gridRef.current &&
      gridRef.current.offsetWidth &&
      gridRef.current.offsetWidth !== elementWidth
    ) {
      setTimeout(() => {
        setElementWidth(gridRef.current.offsetWidth)
      }, 0)
    }
  }

  const resizeObserver = new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      handleElementResized()
    })
  })

  useEffect(() => {
    resizeObserver.observe(gridRef.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
  })

  return (
    <S.Wrapper
      min={min}
      space={space}
      isWide={elementWidth > min}
      ref={gridRef}
      data-testid="eezy-layout-grid"
      className={className}
    >
      {children}
    </S.Wrapper>
  )
}

export default Grid
