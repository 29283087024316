import { styled, css } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  itemWidth: string
  height: string
  thumbColor: string
  trackColor: string
  space: number
  overflowing: boolean
  trackHeight: number
}>`
  display: flex;
  height: ${({ height }) => height};
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-color: ${({ thumbColor }) => thumbColor}
    ${({ trackColor }) => trackColor};

  ::-webkit-scrollbar {
    height: ${({ trackHeight }) => themeSpacing(trackHeight)};
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ trackColor }) => trackColor};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ trackColor }) => trackColor};
    background-image: linear-gradient(
      ${({ trackColor }) => trackColor} 0,
      ${({ trackColor }) => trackColor} 0.25rem,
      ${({ thumbColor }) => thumbColor} 0.25rem,
      ${({ thumbColor }) => thumbColor} 0.75rem,
      ${({ trackColor }) => trackColor} 0.75rem
    );
  }

  > * {
    flex: 0 0 ${({ itemWidth }) => itemWidth};
  }

  > img {
    height: 100%;
    flex-basis: auto;
    width: auto;
  }

  > * + * {
    margin-left: ${({ space }) => themeSpacing(space)};
  }

  ${({ space, overflowing }) =>
    overflowing &&
    css`
      padding-bottom: ${themeSpacing(space)};
    `}
`
