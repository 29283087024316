import React from 'react'
import { useViewport } from '../../../hooks'

import * as S from './styled'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
}

const ModalHeader = ({ children, ...rest }: Props): JSX.Element => {
  const { isMobile } = useViewport()

  return (
    <S.Wrapper
      data-testid="modal-header"
      variant={isMobile ? 'mediumBoldText' : 'h4'}
      $isMobile={isMobile}
      id={'modal-header'}
      {...rest}
    >
      {children}
    </S.Wrapper>
  )
}
export default ModalHeader
