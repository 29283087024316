import { Typography } from '@mui/material'
import {
  eachDayOfInterval,
  eachHourOfInterval,
  format,
  isToday,
  parseISO,
  subDays,
  subHours,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { LoadingSpinner, Modal, TooltipIcon } from 'eezy-components'
import { observer } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralReviewStore } from 'stores'
import { useInstance } from 'util/di'

import * as S from './styled'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
}

interface FileReviewMetrics {
  daily_counts: { [key: string]: number }
  hourly_counts: { [key: string]: number }
}

const ReviewedFilesModal = ({ isOpen, setOpen }: Props): JSX.Element => {
  const { fetchFileReviewMetrics, filesReviewedMetrics, loadingMetrics } =
    useInstance<GeneralReviewStore>('GeneralReviewStore')
  const { t } = useTranslation()

  useEffect(() => {
    if (isOpen) {
      fetchFileReviewMetrics()
    }
  }, [fetchFileReviewMetrics, isOpen])

  const metrics: FileReviewMetrics = useMemo(
    () =>
      (filesReviewedMetrics as FileReviewMetrics) || {
        daily_counts: {},
        hourly_counts: {},
      },
    [filesReviewedMetrics]
  )

  const dailyKeys = useMemo(() => {
    const today = new Date()
    const fourteenDaysAgo = subDays(today, 13)
    const daysArray = eachDayOfInterval({
      start: fourteenDaysAgo,
      end: today,
    })

    return daysArray.map(day => format(day, 'yyyy-MM-dd')).reverse()
  }, [])

  const hourlyKeys = useMemo(() => {
    const ctTimeZone = 'America/Chicago'
    const nowInCT = utcToZonedTime(new Date(), ctTimeZone)
    const twelveHoursAgo = subHours(nowInCT, 11)
    const hoursArray = eachHourOfInterval({
      start: twelveHoursAgo,
      end: nowInCT,
    })

    return hoursArray.map(hour => format(hour, 'HH:00')).reverse()
  }, [])

  const totalHourlyCount = useMemo(() => {
    if (
      metrics &&
      !metrics.hourly_counts &&
      Object.values(!metrics.hourly_counts).length === 0
    ) {
      return 0
    }
    return hourlyKeys.reduce(
      (sum, key) => sum + (metrics.hourly_counts[key] || 0),
      0
    )
  }, [hourlyKeys, metrics])

  const formatDate = (dateString: string) => {
    const date = parseISO(dateString)
    return isToday(date)
      ? t('review.metrics.modal.daily.today')
      : format(date, 'MMM d, yyyy')
  }

  const formatTime = (timeString: string) => {
    const ctTimeZone = 'America/Chicago'

    const nowInCT = utcToZonedTime(new Date(), ctTimeZone)
    const currentHourInCT = nowInCT.getHours()

    const [hourString] = timeString.split(':')
    const hour = parseInt(hourString, 10)

    if (hour === currentHourInCT) {
      return t('review.metrics.modal.hourly.now')
    }

    const formattedHour = hour % 12 || 12
    const amPm = hour < 12 ? 'AM' : 'PM'

    return `${formattedHour}${amPm.toLowerCase()}`
  }

  return (
    <Modal
      modalOpen={isOpen}
      setModalOpen={setOpen}
      data-testid="reviewed-files-modal"
      size="larger"
    >
      <Modal.Header>{t('review.metrics.modal.title')}</Modal.Header>
      <Modal.Body>
        {loadingMetrics ? (
          <S.LoadingContainer>
            <LoadingSpinner />
          </S.LoadingContainer>
        ) : (
          <S.Container>
            <S.MetricsTable>
              <S.MetricsTableHeader>
                <Typography variant="mediumBoldText">
                  {t('review.metrics.modal.daily.title')}
                </Typography>
                <TooltipIcon
                  placement="left"
                  isModal
                  interactive
                  tooltipContent={t('review.metrics.modal.daily.tooltip')}
                />
              </S.MetricsTableHeader>
              {dailyKeys.length ? (
                <>
                  {dailyKeys.map(key => (
                    <S.MetricRow key={key}>
                      <Typography variant="body1">{`${formatDate(
                        key
                      )}:`}</Typography>
                      <Typography variant="body1">
                        {metrics.daily_counts[key] || 0}
                      </Typography>
                    </S.MetricRow>
                  ))}
                </>
              ) : (
                t('review.metrics.modal.no_data')
              )}
            </S.MetricsTable>
            <S.MetricsTable>
              <S.MetricsTableHeader>
                <Typography variant="mediumBoldText">
                  {t('review.metrics.modal.hourly.title')}
                </Typography>
                <TooltipIcon
                  placement="left"
                  isModal
                  interactive
                  tooltipContent={t('review.metrics.modal.hourly.tooltip')}
                />
              </S.MetricsTableHeader>
              {hourlyKeys.length ? (
                <>
                  <S.MetricRow>
                    <Typography variant="body1">
                      {t('review.metrics.modal.hourly.total')}
                    </Typography>
                    <Typography variant="body1">{totalHourlyCount}</Typography>
                  </S.MetricRow>
                  {hourlyKeys.map(key => (
                    <S.MetricRow key={key}>
                      <Typography variant="body1">{`${formatTime(
                        key
                      )}:`}</Typography>
                      <Typography variant="body1">
                        {metrics.hourly_counts[key] || 0}
                      </Typography>
                    </S.MetricRow>
                  ))}
                </>
              ) : (
                t('review.metrics.modal.no_data')
              )}
            </S.MetricsTable>
          </S.Container>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default observer(ReviewedFilesModal)
