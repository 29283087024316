import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  andText?: boolean
  gutters?: string
  intrinsic?: boolean
  maxWidth?: string
}

const Center = ({
  andText = false,
  gutters = '0',
  intrinsic = false,
  maxWidth = '60ch',
  children,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      andText={andText}
      gutters={gutters}
      intrinsic={intrinsic}
      maxWidth={maxWidth}
      data-testid="eezy-layout-center"
    >
      {children}
    </S.Wrapper>
  )
}

export default Center
