import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  contentMin: string
  noStretch: boolean
  side: string
  sideWidth: string
  space: number
  wrapReverse: boolean
}>`
  overflow: hidden;

  > * {
    display: flex;
    flex-wrap: ${({ wrapReverse }) => (wrapReverse ? 'wrap-reverse' : 'wrap')};
    margin: calc(${({ space }) => themeSpacing(space)} / 2 * -1);
    ${({ noStretch }) => (noStretch ? 'align-items: flex-start;' : '')}
  }

  > * > * {
    flex-grow: 1;
    ${({ sideWidth }) => (sideWidth ? `flex-basis: ${sideWidth};` : '')}
    margin: calc(${({ space }) => themeSpacing(space)} / 2);
  }

  > * > ${({ side }) => (side !== 'left' ? ':first-child' : ':last-child')} {
    flex-basis: 0;
    flex-grow: 999;
    min-width: calc(
      ${({ contentMin }) => contentMin} - ${({ space }) => themeSpacing(space)}
    );
  }
`
