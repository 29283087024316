import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  contentMin?: string
  noStretch?: boolean
  side?: 'left' | 'right'
  sideWidth?: string
  space?: number
  wrapReverse?: boolean
}

const Sidebar = ({
  contentMin = '50%',
  noStretch = false,
  side = 'left',
  sideWidth = '25%',
  space = 1.5,
  wrapReverse = false,
  children,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      contentMin={contentMin}
      noStretch={noStretch}
      side={side}
      sideWidth={sideWidth}
      space={space}
      wrapReverse={wrapReverse}
      data-testid="eezy-layout-sidebar"
    >
      {children}
    </S.Wrapper>
  )
}

export default Sidebar
