import React, { forwardRef, useEffect, useState } from 'react'
import InputWrapper, { InputWrapperProps } from '../InputWrapper'
import { InputProps } from './types'
import * as S from './styled'

const TextInput = forwardRef<HTMLElement, InputProps>(
  (
    {
      className,
      fullWidth,
      label,
      labelColor,
      labelCaption,
      labelShrink,
      labelAsPlaceholder,
      onClear,
      onChange,
      required,
      size,
      value,
      helperText,
      tooltipContent,
      validate,
      inputProps,
      publiclyVisible,
      inputId,
      disabled,
      error = false,
      type = 'text',
      fakeInput = false,
      ...otherProps
    }: InputProps,
    ref
  ): JSX.Element => {
    const [validation, setValidation] = useState({ valid: true, message: '' })
    const inputWrapperProps: InputWrapperProps = {
      fullWidth,
      label,
      labelColor,
      labelCaption,
      labelShrink,
      labelAsPlaceholder,
      helperText,
      tooltipContent,
      required,
      publiclyVisible,
      error,
    }

    useEffect(() => {
      if (validate) {
        setValidation(validate(value))
      }
    }, [value, validate])

    const handleChange = event => {
      if (validate) {
        setValidation(validate(event.target.value))
      }
      onChange(event)
    }

    const clearAdornment =
      onClear && value?.length ? (
        <S.ClearIcon position="end" onClick={onClear} />
      ) : null

    return (
      <InputWrapper className={className} {...inputWrapperProps}>
        {fakeInput ? (
          <S.FakeInput>{value || ''}</S.FakeInput>
        ) : (
          <>
            <S.StyledInput
              id={`${label || inputId}-input`}
              onChange={handleChange}
              value={value}
              required={required}
              data-testid="text-input"
              ref={ref}
              $valid={validation.valid}
              inputProps={{ size, ...inputProps }}
              endAdornment={!disabled ? clearAdornment : null}
              disabled={disabled}
              type={type}
              {...otherProps}
            />
            {validate ? (
              <S.ErrorMessage>
                {!validation.valid && validation.message}
              </S.ErrorMessage>
            ) : null}
          </>
        )}
      </InputWrapper>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
