import React from 'react'
import { Button } from '../../index'
import ModalHeader from '../ModalHeader'
import ModalActions from '../ModalActions'
import { ButtonProps } from '@mui/material/Button'

import * as S from './styled'

type Props = {
  bodyText?: string | JSX.Element
  confirmButtonAction: () => void
  confirmButtonStyle?: ButtonProps['color']
  confirmButtonText?: string
  confirmLoading?: boolean
  denyButtonAction?: () => void
  denyButtonText?: string
  headerText?: string | JSX.Element
}

const ConfirmDialog = ({
  bodyText = '',
  confirmButtonAction,
  confirmButtonStyle = 'primary',
  confirmButtonText = 'Okay',
  confirmLoading,
  denyButtonAction,
  denyButtonText = 'Cancel',
  headerText = 'Are you sure?',
}: Props): JSX.Element => {
  const hasTextElement = textElement => {
    if (!textElement) return false
    if (typeof textElement === 'string' && textElement.length === 0)
      return false
    return true
  }

  return (
    <>
      {hasTextElement(headerText) && (
        <ModalHeader data-testid="dialog-header">{headerText}</ModalHeader>
      )}

      <S.StyledModalBody data-testid="confirm-dialog">
        {hasTextElement(bodyText) && (
          <S.DialogBody data-testid="dialog-body" variant="body1">
            {bodyText}
          </S.DialogBody>
        )}
      </S.StyledModalBody>

      <ModalActions>
        <Button
          testId="confirm-button"
          loading={confirmLoading}
          onClick={confirmButtonAction}
          color={confirmButtonStyle}
        >
          {confirmButtonText}
        </Button>

        {denyButtonAction && (
          <Button testId="deny-button" onClick={denyButtonAction}>
            {denyButtonText}
          </Button>
        )}
      </ModalActions>
    </>
  )
}

export default ConfirmDialog
