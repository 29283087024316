import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  space: number
  shouldSwitch: boolean
  noCenter: boolean
  spaceBetween: boolean
}>`
  display: flex;
  flex-direction: ${({ shouldSwitch }) => (shouldSwitch ? 'column' : 'row')};
  align-items: ${({ noCenter }) => (noCenter ? 'normal' : 'center')};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? 'space-between' : 'start'};
  gap: ${({ space }) => themeSpacing(space)};
`
