import { styled } from '@mui/system'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  andText?: boolean
  gutters?: string
  intrinsic: boolean
  maxWidth: string
}>`
  box-sizing: content-box;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ maxWidth }) => maxWidth};
  ${({ intrinsic }) =>
    intrinsic
      ? `
    align-items: center;
    display: flex;
    flex-direction: column;`
      : ''}
  ${({ gutters }) =>
    gutters
      ? `
    padding-left: ${gutters};
    padding-right: ${gutters};`
      : ''}
    ${({ andText }) => (andText ? 'text-align: center;' : '')}
`
