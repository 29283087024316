import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  fixed: boolean
  breakout: boolean
  margin: number
}>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Contents */

  overflow: auto;
  max-height: ${({ margin }) => `calc(100% - (${themeSpacing(margin)} * 2))`};
  max-width: ${({ margin }) => `calc(100% - (${themeSpacing(margin)} * 2))`};
`
