import React from 'react'
import { spacingFunc } from '../../../theme'
import { useViewport } from '../../../hooks'
import CloseIcon from 'ez-styles/assets/icons/x/x-small.svg'
import { DialogProps } from '@mui/material'

import ModalBody from '../ModalBody'
import ModalHeader from '../ModalHeader'
import ModalActions from '../ModalActions'

import * as S from './styled'

type ModalSize = 'small' | 'medium' | 'large' | 'larger' | 'largest' | 'dialog'

type Props = Partial<DialogProps> & {
  className?: string
  modalOpen: boolean
  setModalOpen: (boolean) => void
  handleClose?: () => void
  size?: ModalSize
  testId?: string
  hideCloseButton?: boolean
  disableClose?: boolean
  disableEnforceFocus?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

const Modal = ({
  className,
  modalOpen,
  setModalOpen,
  handleClose,
  children,
  size = 'medium',
  testId = 'modal',
  hideCloseButton = false,
  disableClose = false,
  ...rest
}: Props): JSX.Element => {
  const onClose = () => {
    if (disableClose) {
      return
    }
    if (handleClose) {
      handleClose()
    } else {
      setModalOpen(false)
    }
  }
  const { isMobile } = useViewport()
  const isDialog = size === 'dialog'
  const modalWidth: string = isMobile
    ? isDialog
      ? '85%'
      : '100%'
    : getWidthFromSize(size)

  return (
    <S.StyledModal
      className={className}
      data-testid={testId}
      open={modalOpen}
      onClose={onClose}
      $width={modalWidth}
      $isMobile={isMobile && !isDialog}
      aria-labelledby="modal-header"
      {...rest}
    >
      <>
        {hideCloseButton || disableClose ? null : (
          <S.CloseButton onClick={onClose} aria-label="Close button">
            <CloseIcon />
          </S.CloseButton>
        )}

        {children}
      </>
    </S.StyledModal>
  )
}

const getWidthFromSize = (size: ModalSize): string => {
  switch (size) {
    case 'small':
      return spacingFunc(50)
    case 'large':
      return spacingFunc(75)
    case 'larger':
      return spacingFunc(90)
    case 'largest':
      return spacingFunc(140)
    case 'medium':
    default:
      return spacingFunc(62)
  }
}

Modal.Body = ModalBody
Modal.Header = ModalHeader
Modal.Actions = ModalActions

export default Modal
