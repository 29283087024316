import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  themeBorderRadius,
  themeBoxShadow,
  themeColor,
  themeSpacing,
} from 'eezy-components'

import _CloseIcon from 'ez-styles/assets/icons/x/x-small.svg'

export const Container = styled('div')`
  position: fixed;
  right: ${themeSpacing(2)};
  bottom: ${themeSpacing(4)};
  width: ${themeSpacing(40)};
  border-radius: ${themeBorderRadius('small')};
  box-shadow: ${themeBoxShadow('popper')};
  background-color: #fff;
  overflow: hidden;
  z-index: 99;
`

export const Body = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${themeSpacing(2)};
  padding: ${themeSpacing(3)};
`

export const Msg = styled(Typography)`
  & > strong {
    font-weight: 600;
  }
`

export const Image = styled('div')<{
  src?: string
}>`
  width: 100%;
  height: ${themeSpacing(22)};
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`

export const CloseIcon = styled(_CloseIcon)`
  position: absolute;
  top: ${themeSpacing(2)};
  right: ${themeSpacing(2)};
  fill: ${themeColor('secondary', 'light')};
  cursor: pointer;
  width: ${themeSpacing(1.5)};
  height: ${themeSpacing(1.5)};

  &:hover {
    fill: ${themeColor('secondary', 'main')};
  }
`
