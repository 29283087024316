import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing } from 'eezy-components'

type StyleProps = {
  isOpen?: boolean
  showOverlay?: boolean
}

export const Container = styled('div')<StyleProps>`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props: StyleProps) => (props.isOpen ? '100vw' : '0')};
  z-index: 9999;
  webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
`

export const Wrapper = styled('div')<StyleProps>`
  background-color: #fff;
  height: 100vh;
  padding: ${(props: StyleProps) =>
    props.showOverlay ? themeSpacing(3) : '0'};
  position: fixed;
  top: 0;
  left: 0;
  width: ${(props: StyleProps) => (props.isOpen ? '90vw' : '0')};
  z-index: 9999;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;

  a {
    opacity: ${(props: StyleProps) => (props.isOpen ? '1' : '0')};
    -webkit-transition: opacity 0.6s ease-in-out;
    -moz-transition: opacity 0.6s ease-in-out;
    -o-transition: opacity 0.6s ease-in-out;
    transition: opacity 0.6s ease-in-out;
  }
`

export const Overlay = styled('div')<StyleProps>`
  background-color: ${themeColor('secondary', 'main')};
  height: 100vh;
  z-index: 999;
  opacity: ${(props: StyleProps) => (props.isOpen ? '.8' : '0')};
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
`

export const LogoWrapper = styled('div')`
  margin-bottom: ${themeSpacing(2)};

  svg {
    width: ${themeSpacing(20)};
    height: ${themeSpacing(6)};
  }

  &:hover {
    cursor: pointer;
  }
`
