import { Typography } from '@mui/material'

import * as S from './styled'

export type Props = {
  type: 'modal' | 'toast'
  message: string
  buttonText: string
  onClick: () => void
  href?: string
  bullets?: string[]
  heroImage: string
}

const PersonaCard = ({
  type,
  message,
  buttonText,
  onClick,
  href,
  bullets,
  heroImage,
}: Props): JSX.Element => (
  <S.Container type={type} data-testid={`persona-${type}`}>
    <S.CloseIcon onClick={onClick} />
    <S.Image type={type} src={heroImage} />

    <S.Body>
      <Typography variant="h6">{message}</Typography>

      {bullets?.length ? (
        <S.List>
          {bullets.map((bullet: string, idx: number) => (
            <li key={`bullet-${idx}`}>
              <S.IconContainer>
                <S.CheckmarkIcon />
              </S.IconContainer>

              <Typography variant="h6">{bullet}</Typography>
            </li>
          ))}
        </S.List>
      ) : null}

      {type === 'modal' ? (
        <S.ActionButton color="primary" onClick={onClick}>
          {buttonText}
        </S.ActionButton>
      ) : (
        <S.ActionButton
          color="primary"
          onClick={onClick}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonText}
        </S.ActionButton>
      )}
    </S.Body>
  </S.Container>
)

export default PersonaCard
