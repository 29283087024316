import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  centered: string
  minHeight: string
  noPadding: boolean
  space: number
}>`
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ noPadding, space }) =>
    !noPadding ? themeSpacing(space) : '0px'};

  > * {
    margin-top: ${({ space }) => themeSpacing(space)};
    margin-bottom: ${({ space }) => themeSpacing(space)};
  }

  > :first-child:not(${({ centered }) => centered}) {
    margin-top: 0;
  }

  > :last-child:not(${({ centered }) => centered}) {
    margin-bottom: 0;
  }

  > ${({ centered }) => centered} {
    margin-top: auto;
    margin-bottom: auto;
  }
`
