import { styled } from '@mui/material/styles'
import Badge from 'components/Badge'
import { Modal, themeSpacing } from 'eezy-components'

export const StyledModal = styled(Modal)`
  & .MuiPaper-root {
    text-align: center;
  }
`

export const StyledBadge = styled(Badge)`
  margin: ${themeSpacing(6)} auto;
`
