import { styled } from '@mui/material/styles'
import { themeColor, themeSpacing, Typography } from 'eezy-components'

type StyleProps = {
  $isActive?: boolean
  $isChild?: boolean
}

export const MenuItemWrapper = styled('div')<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    fill: ${(props: StyleProps) =>
      props.$isActive
        ? themeColor('primary', 'main')
        : themeColor('secondary', 'main')};
    width: ${themeSpacing(2.5)};
    height: ${themeSpacing(2.5)};
  }

  .arrow-icon {
    width: ${themeSpacing(1.5)};
    height: ${themeSpacing(1.5)};
  }

  &:hover {
    cursor: pointer;
    color: ${themeColor('primary', 'main')};
  }
`

export const StyledMenuItem = styled(Typography)<StyleProps>`
  color: ${(props: StyleProps) =>
    props.$isActive
      ? themeColor('primary', props.$isChild ? 'light' : 'main')
      : themeColor('secondary', props.$isChild ? 'light' : 'main')};
  display: block;
  padding: 1rem;
  cursor: ${(props: StyleProps) => (props.$isChild ? 'pointer' : 'inherit')};
  z-index: 9999;
`

export const SubMenuWrapper = styled('div')`
  margin-left: ${themeSpacing(2.35)};
`
