import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  align?: string
  justify?: string
  space?: number
}

const Cluster = ({
  align = 'center',
  justify = 'flex-start',
  space = 1.5,
  children,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      align={align}
      justify={justify}
      space={space}
      data-testid="eezy-layout-cluster"
    >
      {children}
    </S.Wrapper>
  )
}

export default Cluster
