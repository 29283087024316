import React, { useEffect, useRef, useState } from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  space?: number
  threshold?: number
  noCenter?: boolean
  spaceBetween?: boolean
}

const Switcher = ({
  space = 1.5,
  threshold = 768,
  noCenter,
  spaceBetween,
  children,
}: Props): JSX.Element => {
  const switcherRef = useRef<HTMLDivElement>(null)
  const [elementWidth, setElementWidth] = useState(null)

  const handleElementResized = () => {
    if (
      switcherRef &&
      switcherRef.current &&
      switcherRef.current.offsetWidth &&
      switcherRef.current.offsetWidth !== elementWidth
    ) {
      setTimeout(() => {
        setElementWidth(switcherRef.current.offsetWidth)
      }, 0)
    }
  }

  const resizeObserver = new ResizeObserver(() => {
    window.requestAnimationFrame(() => {
      handleElementResized()
    })
  })

  useEffect(() => {
    resizeObserver.observe(switcherRef.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
  })

  return (
    <S.Wrapper
      space={space}
      shouldSwitch={elementWidth < threshold}
      noCenter={!!noCenter}
      spaceBetween={!!spaceBetween}
      ref={switcherRef}
      data-testid="eezy-layout-switcher"
    >
      {children}
    </S.Wrapper>
  )
}

export default Switcher
