import React, { ReactNode, useState } from 'react'
import Modal from '../../modal/Modal'
import useViewport from '../../../hooks/useViewport'
import * as S from './styled'

type Props = {
  className?: string
  clickToShow?: boolean
  tooltipContent: ReactNode
  tooltipHeader?: string
  placement?: string
  interactive?: boolean
  children: ReactNode | ReactNode[]
  triggerFullWidth?: boolean
  timer?: number
  type?: 'error' | undefined
  disabled?: boolean
  isModal?: boolean
  disableMobileTooltip?: boolean
}

const ThemedTooltip = ({
  className,
  children,
  clickToShow,
  tooltipContent,
  tooltipHeader,
  placement = 'left',
  interactive = false,
  triggerFullWidth,
  timer,
  type,
  disabled,
  isModal,
  disableMobileTooltip = false,
}: Props): JSX.Element => {
  const { isMobile } = useViewport()
  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const openMobileDialog = () => setDialogOpen(true)

  let timerTimeout

  const clickOpen = () => {
    clearTimeout(timerTimeout)

    setOpen(timer ? true : !open)

    if (timer) {
      timerTimeout = setTimeout(() => {
        setOpen(false)
      }, timer)
    }
  }

  return isMobile && !disableMobileTooltip ? (
    <>
      <S.TriggerContainer
        onClick={openMobileDialog}
        $fullWidth={triggerFullWidth}
        data-testid="tooltip-trigger"
      >
        {children}
      </S.TriggerContainer>

      {dialogOpen ? (
        <Modal
          size={isModal ? 'large' : 'dialog'}
          modalOpen
          hideCloseButton={!isModal}
          setModalOpen={setDialogOpen}
        >
          {tooltipHeader ? <Modal.Header>{tooltipHeader}</Modal.Header> : null}
          <S.TooltipContent $isModal={isModal} data-testid="tooltip-content">
            {tooltipContent}
          </S.TooltipContent>
        </Modal>
      ) : null}
    </>
  ) : (
    <S.StyledTooltip
      arrow
      className={className}
      title={
        <S.TooltipInner data-testid="tooltip-content">
          {tooltipContent}
        </S.TooltipInner>
      }
      disableFocusListener={clickToShow}
      disableHoverListener={clickToShow}
      placement={placement}
      open={clickToShow && open}
      disableInteractive={!interactive}
      type={type}
      disabled={disabled}
    >
      <S.TriggerContainer
        onClick={clickToShow && clickOpen}
        $fullWidth={triggerFullWidth}
        data-testid="tooltip-trigger"
      >
        {children}
      </S.TriggerContainer>
    </S.StyledTooltip>
  )
}

export default ThemedTooltip
