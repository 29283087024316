import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  borderWidth?: string
  invert?: boolean
  padding?: number
}

const Box = ({
  borderWidth = '0',
  invert = false,
  padding = 1.5,
  children,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      borderWidth={borderWidth}
      invert={invert}
      padding={padding}
      data-testid="eezy-layout-box"
    >
      {children}
    </S.Wrapper>
  )
}

export default Box
