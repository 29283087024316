// @ts-nocheck ($FixMe)
import * as Sentry from '@sentry/react'
import { GridSize } from 'constants/resources'
import { captureError } from 'helpers/error'
import {
  removeProhibitedKeywords,
  removeProhibitedTermsFromTitle,
} from 'helpers/resources'
import {
  action,
  computed,
  isObservable,
  makeAutoObservable,
  makeObservable,
  observable,
  runInAction,
} from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'
import { FileUpload, Rating, ResourceClickArgs } from 'types'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'
import {
  FilestackImport,
  PatchApiV1Resources,
  ProhibitedTerm,
  Resource,
  ResponsesBulkUpdateSuccess,
  SubmissionQuota,
} from '../api'
import AuthStore from './AuthStore'
import GeneralReviewStore from './GeneralReviewStore'

type ResourceWithModifiableTitle = Resource & {
  modifiableTitleContent: string
}

type IResource = ResourceWithModifiableTitle &
  IViewModel<ResourceWithModifiableTitle>

export default class ResourceStore {
  api: Api
  lastSelected: Resource | null
  AuthStore: AuthStore
  GeneralReviewStore: GeneralReviewStore

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
    this.GeneralReviewStore = container.find('GeneralReviewStore')
    this.lastSelected = null
  }

  // these are mobx observable models
  @observable collection: Resource[] = []
  // ids of selected resources
  @observable selectedResources: string[] = []
  @observable shouldSelectAllForAllPages = false
  // these are mobx viewmodels i.e. proxies to models and can track changes
  @observable visibleResources: IResource[] = []
  // fallback of most recently refreshed resources
  @observable originalResources: IResource[] = []
  @observable refreshedIds: string[] = []
  @observable gridSize: GridSize = GridSize.Medium
  @observable gridSizes: GridSize[] = []
  @observable panelsOpen: 0 | 1 | 2 = 0
  @observable bundleModalVisibility = false
  @observable originalResource: Resource | undefined = undefined
  @observable submissionQuotas: SubmissionQuota[] = []
  @observable defaultMarketplaces: Marketplace[] = []

  @action set = (collection: Resource[] | IResource[]): void => {
    this.collection = collection.map(resource =>
      isObservable(resource) ? resource : makeAutoObservable(resource)
    )

    this.visibleResources = collection.map(resource => {
      let res: IResource
      if ('model' in resource) {
        res = resource as IResource
      } else {
        res = createViewModel(resource) as IResource
      }

      return res
    })

    if (this.shouldSelectAllForAllPages) {
      this.selectAllVisibleResources()
    }
  }

  get = (id: string): Resource =>
    this.collection.find(x => x.id === id) as unknown as Resource

  getEdited = (id: string): Resource =>
    this.visibleResources.find(x => x.id === id) as unknown as Resource

  getAll = (resources: Resource[]): Resource[] => {
    const arr = resources.map(x => x.id)
    return this.visibleResources.filter(x => arr.indexOf(x.id) > -1)
  }

  @action add = (resource: Resource): void => {
    const idx = this.collection.findIndex(x => x.id === resource.id)

    if (idx === -1) {
      this.collection.push(resource)
    }
  }

  @action removeResources = (ids: string[]): void => {
    this.visibleResources = this.visibleResources.filter(
      res => !ids.includes(res.id)
    )
    this.collection = this.collection.filter(res => !ids.includes(res.id))
  }

  @action resetEditedResources = (): void => {
    this.visibleResources.forEach(resourceViewModel => {
      resourceViewModel.reset()
    })
  }

  @computed get resourcesAreDirty(): boolean {
    return this.visibleResources?.some(resource => resource.isDirty)
  }

  getSelectedEditableResources = (resources: Resource[]): Resource[] => {
    const selectedResourceIds = resources.map(x => x.id)
    return this.visibleResources.filter(resource =>
      selectedResourceIds.includes(resource.id)
    )
  }

  cleanProhibitedTerms = (
    resource: IResource,
    prohibitedTerms: ProhibitedTerm[]
  ) => {
    const updatedResource = resource
    let keywords = updatedResource.keywords
    let title = updatedResource.title

    if (prohibitedTerms && prohibitedTerms.length) {
      if (title) {
        title = removeProhibitedTermsFromTitle(title, prohibitedTerms)
      }

      if (keywords && keywords.length) {
        keywords = removeProhibitedKeywords(keywords, prohibitedTerms)
      }
    }

    updatedResource.title = title
    updatedResource.keywords = keywords

    return updatedResource
  }

  @action setRefreshedResources = (
    updatedIds: string[],
    prohibitedTerms?: ProhibitedTerm[] | null
  ): void => {
    updatedIds.forEach(id => {
      let resource = this.visibleResources.find(
        res => res.id === id,
        prohibitedTerms
      )

      if (prohibitedTerms && prohibitedTerms.length) {
        resource = this.cleanProhibitedTerms(resource, prohibitedTerms)
      }

      const viewModel = resource
      viewModel?.submit() // resets the viewmodel and updates corresponding model in this.collection
    })
    this.refreshedIds = updatedIds
  }

  @action setLockedReason = (
    resourceIds: string[],
    reason: Resource['locked_reason']
  ): void => {
    const collectionCopy = [...this.visibleResources]

    resourceIds.forEach(id => {
      const resource = this.visibleResources.find(res => res.id === id)
      if (resource) {
        resource.locked_reason = reason
        resource.model.locked_reason = reason
        resource.resetProperty('locked_reason')
      }
    })

    this.visibleResources = collectionCopy
  }

  @action setTitle = (id: string, title: string, refresh = false): void => {
    const resource = this.visibleResources.find(res => res.id === id)

    if (resource) {
      resource.title = title

      if (refresh) {
        this.setVisible(this.visibleResources)
      }
    }
  }

  @action setKeywords = (
    id: string,
    keywords: string[],
    refresh = false
  ): void => {
    const resource = this.visibleResources.find(res => res.id === id)

    if (resource) {
      resource.keywords = keywords

      if (refresh) {
        this.setVisible(this.visibleResources)
      }
    }
  }

  @action setRating = (id: string, rating: Rating, refresh = false): void => {
    const resource = this.visibleResources.find(res => res.id === id)

    if (resource) {
      resource.rating = rating

      if (refresh) {
        this.setVisible(this.visibleResources)
      }
    }
  }

  @action saveReviewResource = async (resourceId: string): Promise<void> => {
    const { currentReviewSessionId } = this.GeneralReviewStore
    const updated = this.originalResources.slice()
    const editedResource = this.getEdited(resourceId)

    if (currentReviewSessionId) {
      editedResource.review_session_id = currentReviewSessionId
    }

    if (editedResource) {
      try {
        const res = await this.api.request('PATCH', '/api/v1/resources', {
          body: {
            resources: [this.cleanEditedResource(editedResource)],
            review_assigned_to_me: true,
          },
        })

        if (!res?.failed_ids?.includes(resourceId)) {
          const idx = updated.findIndex(x => x.id === resourceId)

          if (idx > -1) {
            updated[idx] = editedResource
            this.setOriginal(updated)
          }
        }
      } catch (error) {
        Sentry.captureException(error)
        throw error
      }
    } else {
      throw new Error('Resource not found in list')
    }
  }

  @action saveEditedResources = async (
    updateResourceIds?: string[],
    prohitbitedTerms?: ProhibitedTerm[] | null
  ): Promise<ResponsesBulkUpdateSuccess & { succeededIds: string[] }> => {
    const resourceIds = updateResourceIds
      ? updateResourceIds
      : this.visibleResources.map(r => r.id)
    const editedResources = resourceIds
      .map(id => this.getEdited(id))
      .filter((r): r is Resource => Boolean(r))

    // requirement for not saving a blank field if it had a value already
    const resources = editedResources.map(editedResource => {
      const originalResource = this.get(editedResource.id)
      const overriddenResource: PatchApiV1Resources['resources'][0] = {
        ...editedResource,
      }
      Object.entries(overriddenResource).forEach(([_key, value]) => {
        const key = _key as keyof Resource
        const originalValue = originalResource ? originalResource[key] : null

        if (!['pro'].includes(key) && !value && Boolean(originalValue)) {
          overriddenResource[key] = originalValue
        }
      })

      if (overriddenResource.category) {
        overriddenResource.category_id = overriddenResource.category.id
      }

      overriddenResource.ai_generated = Boolean(
        editedResource.meta_data?.ai_generated
      )
      overriddenResource.ai_generator = editedResource.meta_data?.ai_generator

      if (
        overriddenResource.ai_generated ||
        (overriddenResource?.category?.title.toLocaleLowerCase() !== 'photo' &&
          overriddenResource?.category?.title.toLocaleLowerCase() !== 'vector')
      ) {
        overriddenResource.marketplace_account_ids = []
      }

      return overriddenResource
    })

    try {
      const res = await this.api.updateResources(resources)
      const { failed_ids: failedIds } = res
      const succeededIds = resourceIds.filter(id => !failedIds.includes(id))
      this.setRefreshedResources(succeededIds, prohitbitedTerms)
      return { ...res, succeededIds }
    } catch (error) {
      captureError(
        error,
        'Error while saving edited resources - stores/ResourceStore.ts'
      )
      throw error
    }
  }

  cleanEditedResource = (editedResource: Resource): Resource => {
    const originalResource = this.get(editedResource.id)
    const overriddenProperties = []

    Object.entries(editedResource).forEach(([key, value]) => {
      // need to ignore boolean properties...
      if (!['pro'].includes(key) && !value && Boolean(originalResource[key])) {
        overriddenProperties.push([key, originalResource[key]])
      }
    })

    if (!overriddenProperties.length) {
      return editedResource
    } else {
      const overriddenResource = { ...editedResource }
      overriddenProperties.forEach(([key, val]) => {
        overriddenResource[key] = val
      })
      return overriddenResource
    }
  }

  @action deleteResources = async (
    resourceIds: string[]
  ): Promise<ResponsesBulkUpdateSuccess> => {
    try {
      const res = await this.api.request('DELETE', '/api/v1/resources', {
        query: { ids: resourceIds.toString() },
      })
      const { failed_ids: failedIds } = res
      const succeededIds = resourceIds.filter(id => !failedIds.includes(id))
      this.removeResources(succeededIds)
      return res
    } catch (error) {
      captureError(
        error,
        'Error while deleting resources - stores/ResourceStore.ts'
      )
      throw error
    }
  }

  @action submitResources = async (
    resourceIds: string[],
    shouldBeSubmitted: boolean
  ): Promise<ResponsesBulkUpdateSuccess & { succeededIds: string[] }> => {
    const { session } = this.AuthStore

    const resources = resourceIds.map(id => {
      const original = this.getEdited(id)
      const editedResource: PatchApiV1Resources['resources'][0] = {
        ...original,
      }

      let resourceState = 'submitting'

      if (shouldBeSubmitted || editedResource.magic_ai_rejection) {
        resourceState = 'submitted'
      }

      editedResource.state = resourceState

      if (editedResource.category) {
        editedResource.category_id = editedResource.category.id
      }

      if (editedResource.release_ids?.length) {
        editedResource.release_ids = editedResource.release_ids.filter(id => {
          if (id === null) {
            addBreadcrumb({
              category: 'submitResources',
              message: `Edited resource being submitted had null release_id. Resource id: ${reviewed.resource.id}`,
              level: Severity.Info,
            })
            return false
          }
          return true
        })
      }

      if (session?.wo) {
        editedResource.license_set = true
      }

      if (
        editedResource.magic_ai_rejection ||
        editedResource.license === 'editorial'
      ) {
        editedResource.magic_metadata = false
      }

      editedResource.ai_generated = Boolean(original.meta_data?.ai_generated)
      editedResource.ai_generator = original.meta_data?.ai_generator

      if (
        editedResource.ai_generated ||
        (editedResource?.category?.title.toLocaleLowerCase() !== 'photo' &&
          editedResource?.category?.title.toLocaleLowerCase() !== 'vector')
      ) {
        editedResource.marketplace_account_ids = []
      }

      return editedResource
    })

    try {
      const res = await this.api.updateResources(resources)
      const { failed_ids: failedIds } = res
      const succeededIds = resourceIds.filter(id => !failedIds.includes(id))
      this.setRefreshedResources(succeededIds)
      return { ...res, succeededIds }
    } catch (error) {
      captureError(
        error,
        'Error while submitting resources - stores/ResourceStore.ts'
      )
      throw error
    }
  }

  @action createResource = async (
    file: FileUpload
  ): Promise<FilestackImport | Error> => {
    const body = {
      uploads: [{ url: file.url, mimetype: file.mimetype }],
    }

    try {
      const batch = await this.api.request(
        'POST',
        '/api/v1/filestack_imports',
        { body }
      )

      if (batch?.id) {
        const res = await this.api.request(
          'GET',
          `/api/v1/filestack_imports/${batch.id}`
        )

        if (res?.id) {
          return res
        } else {
          throw new Error()
        }
      }
    } catch (err) {
      throw new Error('Failed during resource creation')
    }
  }

  @action hydrateResource = async (
    id: string | number,
    fields: string[] = []
  ): Promise<Resource[]> => {
    try {
      const res: Resource[] = await this.api.request(
        'GET',
        `/api/v1/resources?ids=${id}`
      )

      if (res?.length) {
        const resource = res.find(x => x.id === id)
        const idx = this.collection.findIndex(x => x.id === resource.id)

        if (idx > -1) {
          runInAction(() => {
            if (fields.length) {
              fields.forEach(
                field => (this.collection[idx][field] = resource[field])
              )
            } else {
              this.collection[idx] = resource
            }
          })

          const visibleIdx = this.visibleResources.findIndex(
            x => x.id === resource.id
          )

          if (visibleIdx > -1) {
            runInAction(() => {
              if (fields.length) {
                fields.forEach(
                  field =>
                    (this.visibleResources[visibleIdx][field] = resource[field])
                )
              } else {
                this.visibleResources[visibleIdx] = createViewModel(
                  this.collection[idx]
                )
              }
            })
          }
        }
      }

      return res
    } catch (err) {
      captureError(
        err,
        'Error while hydrating resources - stores/ResourceStore.ts'
      )
      throw new Error('Failed during resource hydration')
    }
  }

  pickChangedOrVisible = (
    initial: Resource[],
    selection: Resource[]
  ): Resource[] => {
    const changed = this.getAll(selection)
    const updated: Resource[] = initial
      .slice()
      .filter((x): x is Resource => Boolean(x))

    selection.forEach(resource => {
      const idx = updated.findIndex(x => x.id === resource.id)
      if (idx == -1) {
        const changedIdx = changed.findIndex(x => x.id === resource.id)
        if (changedIdx == -1) {
          updated.push(resource)
        } else {
          updated.push(changed[changedIdx])
        }
      }
    })

    return Array.from(new Set(updated))
  }

  @action resourceClick = ({
    event,
    resource,
    single = false,
  }: ResourceClickArgs): void => {
    this.shouldSelectAllForAllPages = false

    if (single) {
      if (
        this.visibleResources.length === 0 ||
        !this.visibleResources.find(res => res.id === resource.id)
      ) {
        this.deselectAll()
      } else {
        this.setSelected([resource])
      }
    } else if (event.shiftKey && this.lastSelected) {
      const fromIdx = this.visibleResources.findIndex(
        res => res.id === this.lastSelected?.id
      )
      const currentIdx = this.visibleResources.findIndex(
        res => res.id === resource.id
      )
      const affectedResources = this.visibleResources.reduce(
        (acc, res, idx) =>
          idx >= Math.min(fromIdx, currentIdx) &&
          idx <= Math.max(fromIdx, currentIdx)
            ? [...acc, res]
            : acc,
        [] as Resource[]
      )

      const hasUnselected =
        affectedResources.length === 1
          ? affectedResources.some(
              res => !this.selectedResources.includes(res.id)
            )
          : affectedResources
              .filter(res => res.id !== this.lastSelected?.id)
              .some(res => !this.selectedResources.includes(res.id))

      if (hasUnselected) {
        this.selectResources(affectedResources)
      } else {
        this.deselectResources(affectedResources)
      }
    } else if (event.ctrlKey || event.metaKey) {
      const selected = this.selectedResources.find(id => id == resource.id)

      if (selected) {
        this.deselectResources([resource])
      } else {
        this.selectResources([resource])
      }
    } else {
      this.setSelected([resource])
    }

    this.lastSelected = resource
  }

  @action resourceClickMobile = ({ resource }: ResourceClickArgs): void => {
    const selected = this.selectedResources.find(id => id == resource.id)

    if (selected) {
      this.deselectResources([resource])
    } else {
      this.selectResources([resource])
    }
  }

  @action selectResources = (resources: Resource[] | IResource[]): void => {
    const updated = this.pickChangedOrVisible(
      this.selectedResources.map(this.getEdited),
      resources
    )
    this.selectedResources = updated.map(({ id }) => id)
  }

  @action deselectResources = (resources: Resource[] | IResource[]): void => {
    if (!resources) {
      return
    }
    const new_resources: Resource[] = []
    this.selectedResources.forEach(id => {
      const resource = this.getEdited(id)
      if (resource && resources.length > 0) {
        if (!resources.find(res => res.id === resource.id)) {
          new_resources.push(resource)
        }
      }
    })
    this.selectedResources = new_resources.map(({ id }) => id)
  }

  @action deselectAll = (): void => {
    this.selectedResources = []
  }

  @action updateSelected = (cb: (res: IResource) => void): void => {
    this.selectedResources.map(id => this.getEdited(id)).forEach(cb)
  }

  @action setShouldSelectAllForAllPages = (value: boolean): void => {
    this.shouldSelectAllForAllPages = value
  }

  @action selectAllVisibleResources = (): void => {
    this.setSelected(this.visibleResources, true)
  }

  @action setSelected = (
    resources: Resource[],
    skipDeselect?: boolean
  ): void => {
    if (!resources) {
      return
    } else if (
      this.selectedResources.length === 1 &&
      this.selectedResources[0] === resources[0]?.id &&
      !skipDeselect
    ) {
      return this.deselectAll()
    }

    const updated = this.pickChangedOrVisible([], resources)
    this.selectedResources = updated.map(({ id }) => id)
  }

  @action setVisible = (resources?: Resource[]): void => {
    if (!resources) return
    this.set(resources)
  }

  @action setOriginal = (resources?: Resource[]): void => {
    if (!resources) return
    this.originalResources = resources
  }

  @action setSelectedResources = (resources: Resource[]): void => {
    this.selectedResources = resources.map(x => x.id)
  }

  @action setPanelsOpen = (count: 0 | 1 | 2): void => {
    this.panelsOpen = count
  }

  @action setGridSize = (
    size: GridSize,
    sizes = [GridSize.XLarge, GridSize.Large, GridSize.Medium]
  ): void => {
    this.gridSize = size
    this.gridSizes = sizes
  }

  @action updateGridSize = (size: GridSize): void => {
    this.gridSize = size
  }

  @action resetGridSize = (): void => {
    this.gridSize = undefined
    this.gridSizes = []
  }

  @action setBundleModalVisibility = (
    state: boolean,
    resource?: Resource
  ): void => {
    this.bundleModalVisibility = state
    if (resource && this.originalResource === undefined) {
      this.originalResource = { ...resource }
    } else {
      this.originalResource = undefined
    }
  }

  @action resetResource = (resource: Resource) => {
    const resources = [...this.visibleResources]
    const resourceIndex = resources.findIndex(r => r.id === resource.id)

    if (resourceIndex > -1) {
      resources[resourceIndex] = resource
    }

    this.visibleResources = resources
  }

  @action setSubmissionQuotas = (quotas: SubmissionQuota[] | null): void => {
    if (!quotas) return
    this.submissionQuotas = quotas
  }

  @action updateSubmissionQuotas = (submittedResources: Resource[]): void => {
    submittedResources.forEach(resource => {
      const quota = this.submissionQuotas.find(
        q => Number(q.category_id) === Number(resource.category_id)
      )
      if (quota && quota.resource_count !== undefined) {
        quota.resource_count += 1
      }
    })
  }

  @action getResourceById = (resourceId: string) => {
    const resource = this.getEdited(resourceId)
    return resource || null
  }

  @action setDefaultMarketplaces = (marketplaces: Marketplace[]) => {
    this.defaultMarketplaces = marketplaces
  }

  @computed get computedGridSize(): GridSize {
    if (!this.gridSize) {
      return GridSize.Fill
    }

    if (this.panelsOpen === 2) {
      switch (this.gridSize) {
        case GridSize.XSmall:
          return GridSize.Medium
        case GridSize.Small:
          return GridSize.Large
        case GridSize.Medium:
        case GridSize.Large:
        case GridSize.XLarge:
          return GridSize.XLarge

        default:
          return this.gridSize
      }
    } else if (this.panelsOpen === 1) {
      switch (this.gridSize) {
        case GridSize.XSmall:
          return GridSize.Small
        case GridSize.Small:
          return GridSize.Medium
        case GridSize.Medium:
          return GridSize.Large
        case GridSize.Large:
        case GridSize.XLarge:
          return GridSize.XLarge

        default:
          return this.gridSize
      }
    }

    return this.gridSize
  }
}
