import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  align: string
  justify: string
  space: number
}>`
  > * {
    align-items: ${({ align }) => align};
    display: flex;
    justify-content: ${({ justify }) => justify};
    margin: calc(${({ space }) => themeSpacing(space)} / 2 * -1);
    flex-wrap: wrap;
  }

  > * > * {
    margin: calc(${({ space }) => themeSpacing(space)} / 2);
  }
`
