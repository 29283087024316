import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  invert: boolean
  borderWidth?: string
  padding?: number
}>`
  ${({ invert }) =>
    invert
      ? `
  background-color: #fff;
  filter: invert(100%);`
      : ''}
  background-color: inherit;
  border-width: ${({ borderWidth }) => borderWidth};
  border: ${({ borderWidth }) => borderWidth} solid;
  display: block;
  padding: ${({ padding }) => themeSpacing(padding)};

  /* ↓ For high contrast mode */
  outline: 1px solid transparent;
  outline-offset: calc(1px * -1);
`
