import React from 'react'
import { useViewport } from '../../../hooks'

import * as S from './styled'

type Props = {
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

const ModalBody = ({ className, children, ...rest }: Props): JSX.Element => {
  const { isMobile } = useViewport()

  return (
    <S.Wrapper
      data-testid="modal-body"
      className={className}
      $isMobile={isMobile}
      {...rest}
    >
      {children}
    </S.Wrapper>
  )
}

export default ModalBody
