import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  breakout?: boolean
  margin?: number
  fixed?: boolean
}

const Imposter = ({
  breakout = false,
  margin = 0,
  fixed = false,
  children,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      breakout={breakout}
      margin={margin}
      fixed={fixed}
      data-testid="eezy-layout-imposter"
    >
      {children}
    </S.Wrapper>
  )
}

export default Imposter
