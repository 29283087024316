import React from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  recursive?: boolean
  splitAfter?: number | null
  space?: number
  testId?: string
}

const Stack = ({
  space = 1.5,
  splitAfter = null,
  recursive,
  children,
  testId,
}: Props): JSX.Element => {
  return (
    <S.Wrapper
      space={space}
      splitAfter={splitAfter}
      recursive={!!recursive}
      data-testid={testId ? testId : 'eezy-layout-stack'}
    >
      {children}
    </S.Wrapper>
  )
}

export default Stack
