import { css } from 'styled-components'
import { styled } from '@mui/system'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  recursive: boolean
  space: number
  splitAfter?: number
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${({ recursive, space, theme }) => {
    const spaceText = theme.spacing(space)
    const text = recursive
      ? `* + * { margin-top: ${spaceText}; }`
      : `> * + * { margin-top: ${spaceText}; }`
    return text
  }}

  ${({ splitAfter }) =>
    splitAfter
      ? css`
          .stack-l > :nth-child(${splitAfter}) {
            margin-bottom: auto;
          }
        `
      : ''}
`
