import React from 'react'
import { useViewport } from '../../../hooks'

import * as S from './styled'

type Props = {
  fullWidth?: boolean
  children?: React.ReactNode | React.ReactNode[]
}

const ModalActions = ({ children, fullWidth = false }: Props): JSX.Element => {
  const { isMobile } = useViewport()

  return (
    <S.Wrapper
      data-testid="modal-actions"
      $fullWidth={fullWidth}
      $isMobile={isMobile}
    >
      {children}
    </S.Wrapper>
  )
}

export default ModalActions
